import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import ImpressumLinks from "./ImpressumLinks";

interface TrapezoidProps {
  inhalt?: string;
  herstellerDownloads: [];
}

const HerstellerDownloadLinkWrapper = styled.div`
display: flex;
  flex-wrap: wrap;
  padding:0 80px;
`

const HerstellerDownloadLink = styled.a `
  font-size:12px !important;
  color:red !important;
  padding:5px 10px;
`

const TrapezoidDiv = styled.div`
  height: 12.5vw;
  width: 50vw;
  margin: 0 auto;
  margin-bottom: -1px;
  background: url("/assets/gestaltungselemente/footer-mobile-and-nav-trapez.svg");

  background-size: 100%;
  background-position: center;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 240px;
  max-width: 960px;

  @media (min-width:800px){
    padding-bottom:0px !important;
  }
  .hiddenMobile
  {
    display:block;

    @media (max-width:800px){
      display:none;
    }
  }

  @media (max-width:470px){
    padding-top:50px
  }
  @media (max-width:800px){
    padding-top:30px
  }

  select
  {
    border-radius:0;
    -webkit-appearance: none;
    color:black;
    padding:10px 10px 10px 10px;
    border:1px solid white;
    cursor:pointer;
    @media (max-width:800px){
      margin-top:10px;
      background-color:black;
      border-color:black;
      color:white;
    }
  }

  > a, div {
    display: flex;
    flex-direction: row;
    justify-content: center;

    div,
    div span {
      font: 12px / 15px "Oswald Bold";
      color: #fff;
    }

    > div:first-of-type {
      margin-right: 6.4px;
      margin-top: -3px;
    }
  }

  .login {
    width: 14px;
    height: 14px;
    background-image: url("/assets/gestaltungselemente/login.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: contain;
    margin-top: 4px;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    background: url("/assets/gestaltungselemente/footer-and-akkordeon-trapez.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 3.37vw 0 20px;
    justify-content: flex-end;
    margin-top: -1px;

    > a, div {
      margin-bottom: 5px;

      > div:first-of-type {
        margin-right: 9.5px;
      }
      div,
      div span {
        color: #000;
      }
    }

    .login {
      background-image: url("/assets/gestaltungselemente/login-black.svg");
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 3.37vw 0 24px;
    background-repeat: no-repeat;

    > a > div:first-of-type {
      margin-right: 10.5px;
      margin-top: -5px;
    }

    > a, div {
      margin-bottom: 7px;
    }

    > a div, > div div,
    > a div span, > div div span {
      font: 16px / 19px "Oswald Bold";
    }

    .login {
      width: 18px;
      height: 18px;
      margin-top: 5px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 3.37vw 0 30px;
    background-repeat: no-repeat;

    > a > div:first-of-type {
      margin-top: -6px;
    }

    > a {
      margin-bottom: 10px;
    }

    > a div,
    > a div span {
      font: 18px / 21px "Oswald Bold";
    }

    .login {
      width: 20px;
      height: 20px;
      margin-top: 6px;
    }
  }
`;

const Trapezoid: React.FC<TrapezoidProps> = ({
  inhalt,
  herstellerDownloads,
}) => {
  return (
    <TrapezoidDiv>
        <HerstellerDownloadLinkWrapper>
          <div className="selectWrapper">
          <select onChange={(e:any) => {
            window.location.href = e.target.value
          }}>
              <option>{inhalt}</option>
{herstellerDownloads.map((hersteller:any) => {
        return (
              <option value={"/" + hersteller.uri}>{hersteller.title}</option>
        )
      })}
          </select>
      </div>
      </HerstellerDownloadLinkWrapper>
      <div className="hiddenMobile">
        <ImpressumLinks />
      </div>
    </TrapezoidDiv>
  );
};

export default Trapezoid;
