import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import FooterLeft from "./Footer/FooterLeft";
import FooterRight from "./Footer/FooterRight";
import { breakpoints, colors } from "../styles/variables";
import Trapezoid from "./Footer/Trapezoid";

const FooterDiv = styled.footer`
  color: white;

  &.green {
    background-color: ${colors.green};
  }
  > div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    > div:nth-of-type(2) {
      grid-column: span 3;
      order: 1;
    }

    > div:nth-of-type(1) {
      order: 2;
    }

    > div:nth-of-type(3) {
      order: 3;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    &.green {
      background-color: black;
    }
    background: black;
    height: 12.5vw;
    max-height: 240px;

    > div {
      display: grid;
      grid-template-columns: 25vw 50vw 25vw;
      > div:nth-of-type(2),
      > div:nth-of-type(1),
      > div:nth-of-type(3) {
        grid-column: unset;
        order: unset;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > div {
      position: relative;
      grid-template-columns: 25% 50% 25%;
      max-width: 1920px;
      margin: 0 auto;
    }
  }
`;

interface FooterQueryProps {
  cms: {
    Lokalisierung: {
      seitenTitel: string;
      strasseHausnummer: string;
      land: string;
      postleitzahl: string;
      ort: string;
      telefon: string;
      websiteUrl: string;
      herstellerFinden: string;
      herstellerLogin: string;
    };
    HerstellerDownloads: {
      uri: string;
    };
  };
}

const Footer = ({ green }) => {
  const data = useStaticQuery<FooterQueryProps>(graphql`
    query {
      cms {
        Lokalisierung: entry(type: "metaDatenLokalisierung") {
          ... on CMS_metaDatenLokalisierung_Entry {
            seitenTitel
            strasseHausnummer
            land
            postleitzahl
            ort
            telefon
            websiteUrl
            herstellerFinden
            herstellerLogin
            mapsLink
          }
        }
        HerstellerDownloads: entries(type: "herstellerDownloads") {
          title
          uri
        }
      }
    }
  `);
  return (
    <FooterDiv className={green ? "green" : ""}>
      <div>
        <FooterLeft inhalt={data.cms.Lokalisierung} />
        <Trapezoid
          herstellerDownloads={data.cms.HerstellerDownloads}
          inhalt={data.cms.Lokalisierung.herstellerLogin}
        />
        <FooterRight inhalt={data.cms.Lokalisierung.herstellerFinden} />
      </div>
    </FooterDiv>
  );
};

export default Footer;
